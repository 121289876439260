/* Import and define font */
@font-face {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  /* This will allow to use all font-style */
  font-weight: normal;
  /* This will allow to use all font-weight */
  src:
    url(./Assets/fonts/Poppins/Poppins-Black.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-Light.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype'),
    url(./Assets/fonts/Poppins/Poppins-Thin.ttf) format('truetype'),
}

html {
  scroll-behavior: smooth;
  --blackColor: #1C1C1E;
  --darkBlackColor: #161616;
  --whiteColor: #fff;
  --darkGolderColor: #665837;
  --layoutTheme: #191919;
  --layoutSiderMenu: #23211e;
  --tomatoRed: #ff4444;
  --parrotGreen: #00D539;

  --defaultRedcolor: #f62e27;
  --defaultLightTheme: #BBA9E6;
  --defaultFontsFamilyPopins: "Poppins", sans-serif;
  /* --defaultFontsFamilyPopins: sans-serif; */
  --defaultGradientColor: linear-gradient(180deg, var(--defaultLightTheme), var(--defaultLightTheme));
}

*,
body {
  box-sizing: border-box;
  font-family: var(--defaultFontsFamilyPopins) !important;
}


.IconList{
  background-color: var(--defaultLightTheme);
  color: var(--whiteColor);
  border-radius: 50%;
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* Side Scroller */
::-webkit-scrollbar {
  height: 9px;
  width: 4px
}

::-webkit-scrollbar-track {
  background: #d9dcd7
}

::-webkit-scrollbar-thumb {
  background: var(--defaultLightTheme);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #337ec3
}

h2 {
  font-family: sans-serif !important;
  font-weight: 600 !important;
}
h1,h2,h3,h4,h5,h6{
  word-break: break-all;
}

.headingStyle {
  opacity: 0;
  animation: fadeIn 3.5s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* =========================================================== ANTD GLOBAL =========================================================== */
.ant-layout {
  font-family: var(--defaultFontsFamilyPopins) !important;
  font-weight: 600;
}

header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
  background: var(--whiteColor);
}


li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  color: #b08dff !important;
  background: #bba9e642;
  font-weight: bold;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .sideIcon {
  color: #b08dff !important;
}

.sideIcon {
  color: #bba9e68e;
  font-size: 20px;
}

.ant-layout .ant-layout-sider {
  background: #fbf9ff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 25px;
  list-style-position: inside;
  list-style-type: disc;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ant-dropdown-menu-item {
  color: #fff !important;
}

.ant-menu .ant-menu-title-content {
  transition: color 0.3s;
  align-items: center;
  display: flex;
}

.ant-layout-sider-trigger {
  display: none;
}
.ant-layout-sider {
  overflow-y: auto;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  background-color: #BBA9E617;
  border: 1px dashed var(--defaultLightTheme);
}

.ant-table-thead .ant-table-cell {
  background-color: var(--defaultLightTheme) !important;
  color: var(--blackColor) !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-container:not(.jodit_inline) {
  border-radius: 3px;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid var(--defaultLightTheme) !important;
  border-radius: 3px;
}

.jodit-status-bar {
  display: none !important;
}

.ant-breadcrumb-separator {
  font-size: 1.4rem;
}

.ant-input::placeholder {
  color: lightgray !important;
}

.ant-form-item-control-input-content .input__ {
  border: 1px solid var(--defaultLightTheme);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}


span.ant-select-selection-item {
  display: flex;
  text-align: center;
  align-items: center;
  /* color: var(--whiteColor); */
}

.ant-input-outlined:hover {
  border-color: var(--defaultLightTheme);
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;

}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultLightTheme);
}

.ant-select-selector {
  border: 1px solid var(--defaultLightTheme) !important;
  min-height: 2.5rem;
}

.ant-select-single {
  min-height: 2.5rem;
  text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 8rem !important;
  height: 8rem !important;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  border: 1px dashed var(--defaultLightTheme);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  width: 8rem !important;
  height: 8rem !important;
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled):hover {
  border: 1px solid var(--defaultLightTheme) !important;
}

.ant-picker-outlined:hover {
  border: 1px solid var(--defaultLightTheme) !important;
}

input {
  min-height: 2.5rem;
}


.ant-input-outlined:hover {
  border-color: var(--defaultLightTheme);
}


.ant-switch {
  background-color: var(--tomatoRed) !important;
}

.ant-switch.ant-switch-checked {
  background: var(--parrotGreen) !important;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: var(--whiteColor);
  background-color: var(--defaultLightTheme);
  border-color: var(--defaultLightTheme);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-divider-horizontal {
  margin: 24px 0;
  background: var(--defaultLightTheme);
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: var(--defaultLightTheme) !important;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 14px;
  line-height: 30px;
  background: transparent !important;
  border: transparent !important;
}

.ant-tooltip.css-dev-only-do-not-override-1rqnfsa.ant-tooltip-placement-top {
  display: none;
}

.highcharts-button {
  display: none
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: var(--defaultLightTheme);
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: var(--defaultLightTheme) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background-color: var(--defaultLightTheme) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background-color: var(--defaultLightTheme) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: #5f5339;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: #5f5339 !important;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--defaultLightTheme) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--defaultLightTheme) !important;
  border-color: var(--defaultLightTheme);
}

.ant-checkbox .ant-checkbox-inner {
  border: 1px solid var(--defaultLightTheme);
}

.award.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {

  border: none !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
  background: transparent;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* Chart */
text.highcharts-credits {
  display: none;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
  border-bottom: transparent !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

g.highcharts-legend-item.highcharts-area-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

g.highcharts-legend-item.highcharts-map-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

.ant-layout .ant-layout-header {
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
}

.ant-steps-item-active .ant-steps-item-title {
  color: var(--defaultLightTheme) !important;
}

/* ANIMATION */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
  -webkit-transition: opacity 700ms ease-in;
  -moz-transition: opacity 700ms ease-in;
  -ms-transition: opacity 700ms ease-in;
  -o-transition: opacity 700ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 700ms ease-out;
  -webkit-transition: opacity 700ms ease-out;
  -moz-transition: opacity 700ms ease-out;
  -ms-transition: opacity 700ms ease-out;
  -o-transition: opacity 700ms ease-out;
}


/* =========================================================== LOCALE VARIABLES =========================================================== */

a {
  color: inherit;
  text-decoration: none;
}




.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.ant-typography.titles {
  font-family: sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.5rem !important;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 4rem !important;
  height: 3rem !important;
  font-size: 2rem;
  border: 1px solid var(--defaultLightTheme);
  color: var(--defaultLightTheme);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
}

.commonBoxShadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.customSiderWidth {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  flex: 0 0 250px !important;
}

.common_button_edit {
  cursor: pointer;
  font-size: 1.5rem;
}

.lightBlackBg {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: var(--blackColor);
  background: var(--whiteColor);
}

.tomatoRedBg {
  background: var(--tomatoRed) !important;
  color: var(--whiteColor);

}

.br10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.lightThemeColor {
  color: var(--defaultLightTheme) !important;
}

button {
  border-radius: 10px;
  min-width: 4rem;
}

.skeleton_img{
  height: 18rem !important;
  width: 18rem !important;
}

.bgMedia {
  background: url('./Assets/BgPicture.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
}
.deleteTrash{
  background-color: var(--defaultRedcolor);
  height: 23px;
  padding: 0 3px;
  cursor: pointer;
  position: absolute;
  right: 3%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.deleteTrashEdit{
  cursor: pointer;
  position: absolute;

}
.imageFit {
  object-fit: contain;
}
.bgAlbum{
  background: #bba9e62d;
}
.borderActive{
  border-bottom: 2px solid #bba9e670;
  color: #bba9e6c5;
  cursor: pointer;
}
.borderActiveDark{
  border-bottom: 2px solid #bea6f7;
  color: #ba9cfa;
  cursor: pointer;
}

.commonButton {
  height: 2.5rem !important;
  background: var(--defaultLightTheme);
  min-width: 10rem;
  color: var(--whiteColor);
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;
}

form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
  width: 50%;
}

.whiteBg {
  background-color: var(--whiteColor);
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.2rem;
  min-height: 3.8rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
a{
  color: #000 !important;
  text-decoration: none !important;

}

.searchField>input {
  background-color: transparent !important;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.searchField {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.iconStyles {
  font-size: 3.5rem;
  color: var(--defaultLightTheme);
}

.link_style {
  color: var(--darkBlackColor);
  text-decoration: none;
  font-family: var(--defaultFontsFamilyPopins);
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
    width: 80%;
  }
  }

@media screen and (max-width: 992px) {
  form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
    width: 90%;
  }

  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    padding-left: 11px !important;
  }

  li.ant-menu-item.ant-menu-item-only-child {
    padding-left: 11px !important;
  }

  .customSiderWidth {
    width: 65px !important;
    min-width: 65px !important;
    max-width: 65px !important;
    flex: 0 0 65px !important;
  }
}
@media screen and (max-width: 768px){
  .skeleton_img{
    height: 7rem !important;
    width: 7rem !important;
  }
}
.jodit-placeholder{
  color: transparent !important;
}
.ant-image .ant-image-img{
  width: auto !important;
}